import { graphql } from 'gatsby'
import React from 'react'
import Comments from '../components/Comments/Comments'
import Layout from '../components/Layout'
import { SEO } from '../components/SEO'
import Talk from '../components/Talk'
import { PageBody } from '../components/common/PageBody'
import { Image } from '../components/Image'
import { generateJsonLd } from '../components/util'

const description = `Since 2020, I've been sharing knowledge within the welcoming developer community in Singapore. From small online events to large conferences with up to 500 attendees, it's been an incredible journey. Here are some of my recent talks on modern web development practices. Everyone has something valuable to share, and I truly believe in the power of sharing.`

export default function Talks(props) {
  const {
    data: {
      allMarkdownRemark: { edges: talks },
    },
  } = props

  return (
    <Layout>
      <SEO pageTitle="Talks">
        <script type="application/ld+json">
          {generateJsonLd('Talks', description, 'https://trungvose.com/talks/')}
        </script>
      </SEO>
      <PageBody>
        <h1 className="page__title">Talks</h1>
        <p>{description}</p>
        <div className="talk-image-cover">
          <Image
            src="/img/speaking/talks.jpg"
            options="format=auto,width=1280"
            alt="Trung Vo's Talks"
          />
        </div>

        <h2>Conferences</h2>
        <p>
          See all of my previous 👉{' '}
          <a target="_blank" href="https://trungvose.com/talk-videos">
            recorded talks
          </a>
        </p>
        <div className="talks-container">
          {talks.map(({ node: talk }) => (
            <Talk key={talk.fields.slug} talk={talk} />
          ))}
        </div>
        <hr />
        <h2>Speaking at Your Event</h2>
        <p>
          If you’d like to engage me to speak at your event, you can reach me
          via
        </p>
        <ul>
          <li>
            <a href="https://twitter.com/trungvose">@trungvose</a>
          </li>
          <li>
            Send me a <a href="http://m.me/trungvose">message</a>
          </li>
          <li>
            Send me an <a href="mailto:trungvose@gmail.com">email</a>
          </li>
        </ul>
        <p>Thank you! Cảm ơn bạn rất nhiều :)</p>
        <Comments />
      </PageBody>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TalksPageQuery {
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { layout: { eq: "talk" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            organizerLogo
            title
            description
            date
            gifCover
            location
            info
            video
            slide
          }
        }
      }
    }
  }
`
